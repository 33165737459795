export const getScrollPercentage = () => {
  const h = document.documentElement
  const b = document.body
  const st = 'scrollTop'
  const sh = 'scrollHeight'
  const scrollPercentage =
    ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100

  return Math.round(scrollPercentage || 0)
}

export const getScrollPixels = () => {
  return Math.round(window.scrollY || 0)
}
