import cn from 'classnames'
import React from 'react'

type Props = {
  className?: string
  chars?: number
}

/**
 * Mimics a length of text
 * @param chars number of characters to mimic
 */
export const Text = ({ className = 'w-full', chars }: Props) => {
  const style = chars ? { width: `${chars * 0.5}em` } : undefined

  return (
    <div className="animate-pulse">
      <span
        className={cn(
          'inline-block h-[1em] rounded-[0.5em] bg-current opacity-[0.16]',
          className,
        )}
        style={style}
      />
    </div>
  )
}
