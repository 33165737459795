import { useEffectOnce } from './useEffectOnce'
import { LoggerPayload, useLogger } from './useLogger'

function useLogOnMount(eventType: string, eventProperties?: LoggerPayload) {
  const { logEvent } = useLogger()
  useEffectOnce(() => {
    logEvent(eventType, eventProperties)
  })
}

export default useLogOnMount
